import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <p><h2><strike>Christian Homeschool Conference of South Dakota</strike></h2></p>
                <p><h2>Homeschool South Dakota Conference</h2></p>
            </header>
            <div className="major">
                <p><h2>The same inspiring and edifying homeschool conference, just under a new name!</h2></p>
                <h4>When TEACHSD became HSD, CHCSD became the Homeschool South Dakota Conference. </h4>
                <h3><i><a href="http://homeschoolsd.org/conference" target="_blank">Find out about the 2025 Homeschool South Dakota Conference!</a></i></h3>
            </div>
        </div>
    </section>
)

export default Banner
